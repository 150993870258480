<template>
  <div>
    <guest-navbar />
    <guest-slider>
      <template slot="content">
        <div class="mb-4">
          <h5>Log in to your account</h5>
          <hr />
          <div class="alert alert-danger" v-if="has_error">
            Invalid email and/or password
          </div>
          <form id="login-form" @submit.prevent="login">
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Email"
                class="form-control"
                required
                name="email"
                v-model="user.email"
              />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                v-model="user.password"
                placeholder="Password"
                class="form-control"
                required
              />
            </div>
            <div class="form-group text-right">
              <button class="btn btn-secondary">Log In</button>
            </div>
          </form>
          <p class="mb-2">
            <router-link :to="{ name: 'register' }"
              >Don't have an account? Sign Up.</router-link
            >
          </p>
          <p class="mb-2">
            <router-link :to="{ name: 'forgot-password' }"
              >Forgot Your Password? Reset.</router-link
            >
          </p>
        </div>
      </template>
    </guest-slider>
    <guest-footer />
    <guest-quote />
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      has_error: false,
    };
  },

  methods: {
    login() {
      this.has_error = false;
      this.$loader.start();
      this.$axios
        .post("/api/v1/login", this.user)
        .then((response) => {
          this.$auth.setToken(response.data.access_token);
          window.location.href = this.$router.resolve({
            name: "dashboard",
          }).href;
        })
        .catch(() => {
          this.has_error = true;
          this.$loader.stop();
        });
    },
  },
};
</script>
